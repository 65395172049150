// Generated by Framer (f92ac27)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["L7UKLm4r3"];

const variantClassNames = {L7UKLm4r3: "framer-v-4hh0yc"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};


function toResponsiveImage_194x2gw(value) {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "L7UKLm4r3", image: NVPCaAuYB, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "L7UKLm4r3", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-Ut88D", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<Image {...restProps} background={{alt: "", fit: "stretch", intrinsicHeight: 434, intrinsicWidth: 507, pixelHeight: 434, pixelWidth: 507, ...toResponsiveImage_194x2gw(NVPCaAuYB)}} className={cx("framer-4hh0yc", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"L7UKLm4r3"} ref={ref} style={{...style}} transition={transition}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-Ut88D [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Ut88D * { box-sizing: border-box; }", ".framer-Ut88D .framer-qlx4by { display: block; }", ".framer-Ut88D .framer-4hh0yc { height: 320px; overflow: hidden; position: relative; width: 250px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 320
 * @framerIntrinsicWidth 250
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"NVPCaAuYB":"image"}
 */
const FramertgmpxMDd3: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramertgmpxMDd3;

FramertgmpxMDd3.displayName = "buddha";

FramertgmpxMDd3.defaultProps = {height: 320, width: 250};

addPropertyControls(FramertgmpxMDd3, {NVPCaAuYB: {title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramertgmpxMDd3, [])